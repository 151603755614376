/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Image, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Výkup vozidel "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Výkup vozidel&nbsp;"}>
              </Title>

              <Text className="text-box fs--22" content={"Výkup vozidel osobních i nákladních ihned a v hotovosti. Proč prodat vaše vozidlo právě nám ? Protože nejsme autobazar nabízíme individuální řešení šité na míru přímo pro vás. <span style=\"font-weight: bold;\">Volejte&nbsp;&nbsp;&nbsp;+420 777 321 587&nbsp;<a href=\"+420777321587\"><br></a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--08 pt--08" menu={true} name={"vqw4gq36qdg"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--60 pt--60" name={"dkxvoih4u0q"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/20344/157e8aa999da44668e420d7f1877c7bc_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/20344/157e8aa999da44668e420d7f1877c7bc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/157e8aa999da44668e420d7f1877c7bc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/20344/157e8aa999da44668e420d7f1877c7bc_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"Peníze za vaše vozidlo ihned a v hotovosti.<br>Za vaše vozidlo vyplatíme ihned nejvyšší možnou výkupní cenu .<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/20344/d8b366e3a3474f97ba2552812f15eadd_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/20344/d8b366e3a3474f97ba2552812f15eadd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/d8b366e3a3474f97ba2552812f15eadd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/20344/d8b366e3a3474f97ba2552812f15eadd_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"Výkup všech typů vozidel. Vykupujeme osobní užitkové i nákladní vozidla včetně strojů a zemědělské techniky. Výkup vozidel všech značek.&nbsp;<br>Výkup vozidel bez STK<br>Výkup firemních vozidel<br>výkup poškozených vozidel&nbsp;<br>výkup vozidel s velkým počtem najetých kilometrů&nbsp;<br>neodmítneme výkup žádného vozidla.<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/o/i/template/156/img-3_s=350x_.jpg"} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/i/template/156/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-3_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"Přebíráme veškerou záruku za technický stav. prodejme vašeho vozidla právě nám končí vaše starost s případnou reklamací&nbsp; se skrytou vadou a podobě. Veškeré problémy a případné nákladné opravy&nbsp; vyřešíme sami."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2bfrl3m9lgs"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/20344/6a42820688ae4144b45769322886b68a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/20344/6a42820688ae4144b45769322886b68a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/6a42820688ae4144b45769322886b68a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/20344/6a42820688ae4144b45769322886b68a_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"Výkup vozidla u vás doma nebo vaší firmě.&nbsp;<br>pro vozidlo si zdarma přijedeme kamkoliv.&nbsp;<br>odtah vozidla i autovraku je zdarma.<br>Veškeré dokumenty vyřídíme ihned na místě a v hotovosti vaše vozidlo vykoupíme.<br><br>Kupní smlouva nebo protokol o ekologické likvidaci.<br>Veškeré papírování a vyřizování ohledně převodu vozidla vyřešíme do 3 pracovních dnů za Vás.<br><br>nejrychlejší řešení jak se rychle pohodlně a efektivně zbavit vašeho přebytečného nepotřebného havarovaného nebo poškozeného vozidla&nbsp; za nejvýhodnější cenu a hlavně bezstarostně.<br><br>"}>
              </Text>

              <Button className="btn-box" url={""} href={""} target={null} content={"VOLEJTE +420777321587&nbsp;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/20344/e45302e88d354731a0a26fb49620248d_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/20344/e45302e88d354731a0a26fb49620248d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/e45302e88d354731a0a26fb49620248d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/20344/e45302e88d354731a0a26fb49620248d_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"&nbsp;Výkupní cena závisí vždy na vzhledu vozidla a technickém stavu. Orientační cenu výkupu se dozvíte ještě před našim příjezdem.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mhhp8cfmzvg"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Jak&nbsp; objednat výkup vozidla ?&nbsp;"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"Prostřednictvím našeho objednávkového formuláře."}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":380}} content={"Odtah a výkup vozidla&nbsp; je možné objednat Po zavolání či zaslání SMS na číslo: 777 321 587  nebo na naši zdarma linku 800 206 206&nbsp; a nebo emailem&nbsp; blazasrot@seznam.cz&nbsp; sjednáme vyhovující dobu a místo výkupu Vašeho vozidla."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

              <Text className="text-box" content={"Prosíme Vás pokud možno o co nejbližší specifikaci vozidla které nabízíte na výkup abychom mohli stanovit co možná nejpřesnější výkupní cenu.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"is2g4ji3wu"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/d27c87cc58164764954367418e185f56_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/20344/d27c87cc58164764954367418e185f56_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/d27c87cc58164764954367418e185f56_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/e1b76e6d38004b0c980eb14b45eea5c4_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/20344/e1b76e6d38004b0c980eb14b45eea5c4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/e1b76e6d38004b0c980eb14b45eea5c4_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/08966f5528b940b092159d1e9941b025_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/20344/08966f5528b940b092159d1e9941b025_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/08966f5528b940b092159d1e9941b025_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/ba3af50fd0c440d5aa84da8880300a1b_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/20344/ba3af50fd0c440d5aa84da8880300a1b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/ba3af50fd0c440d5aa84da8880300a1b_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/d28136b0ce6644d2804ba92e363c2b9b_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/20344/d28136b0ce6644d2804ba92e363c2b9b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/d28136b0ce6644d2804ba92e363c2b9b_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/7826d2e5badd444c9b853d8ba83611b6_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/20344/7826d2e5badd444c9b853d8ba83611b6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/7826d2e5badd444c9b853d8ba83611b6_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sfvye3dw03m"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/5259324d4378403aad6c1dac657859c5_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/20344/5259324d4378403aad6c1dac657859c5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/5259324d4378403aad6c1dac657859c5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/20344/5259324d4378403aad6c1dac657859c5_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/20344/bde678ce7155428f8f1281c0a73ee0b5_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/20344/bde678ce7155428f8f1281c0a73ee0b5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/20344/bde678ce7155428f8f1281c0a73ee0b5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/20344/bde678ce7155428f8f1281c0a73ee0b5_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1odcdv6 css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontaktni-formular/"} href={"https://autoservisblaza.cz/kontaktni-formular/"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Autoservis Bláža Ostrava - Radvanice</span>&nbsp;<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Pro Vaše auto uděláme vše, co budeme moct.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Jsme Malý a nezávislý autoservis osobních i užitkových vozidel. Měření a seřízení geometrie kol Servis čištění a plnění klimatizací Rychloservis Pneuservis Nástřiky podvozku a dutin Strojní regenerace filtru pevných částic DPF a jiné.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-custom-2);\">Lihovarská 1378/11, Ostrava - Radvanice Areál Bánských strojíren ( BASTRO ) Tel: +420 777 321 587</span>&nbsp;&nbsp;<br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white;\">IČ: 63471710<br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}